@import "spinner";
@import 'animate.css';
@import 'blobs';

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.bx-wrapper, .bx-wrapper * {
  position: initial;
}

/*GENERIC*/
html {
  font-size: 10px;
  -webkit-text-size-adjust: none;
  font-family: Arial, sans-serif !important;
  font-weight: 100;
  height: 100%;
}

html, body {
  //height: 100%;
  width: 100%;
}

body {
  min-height: 100%;
  font-size: 0;
  background-color: transparent;
  font-family: "Lato", Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 4.4rem;
  margin-bottom: 2rem;
  color: $primary-tone;
}

h3 {
  @media (max-width: 321px) {
    font-size: 1.2rem;
  }
}

ul {
  margin: 0;
  padding: 0;
}

hr {
  border-top: 0.5px solid #666;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

input[type="text"], input[type="email"], input[type="password"], textarea {
  background-color: #ffffff;
  border: 1px solid $grey-800;
  box-shadow: none;
  color: #000;
  display: block;
  font-size: 1.5rem;
  padding: 1rem;
  resize: none;
  width: 100%;
  height: auto;
}

input[type="submit"] {
  font-size: 1.5rem;
}

input.error, textArea.error {
  color: #f00 !important;
  border: 1px solid #f00 !important;
}

label.error {
  color: #f00 !important;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  margin-top: .5rem;
}

.form-error {
  ul {
    li {
      color: #f00 !important;
      font-size: 1.2rem;
    }

    list-style: none !important;
  }
}

@media (max-width: 481px) {
  .m-w-100 {
    max-width: 100%;
  }
  .min-w-100-vw {
    min-width: calc(100vw - 30px);
  }
}

@media only screen and (min-width: 481px) {
  .flex-row.row {
    display: flex;
    flex-wrap: wrap;
  }
  .flex-row.row > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  .flex-row.row:after,
  .flex-row.row:before {
    display: flex;
  }
}

.row-padding {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.fw-normal {
  font-weight: 400;
}

/*GENERIC*/
/*HEADER & FOOTER*/
.header-wrap {
  position: sticky;
  top: 0;
}

.header-wrap, .footer-wrap {
  padding: 1rem 0;
  z-index: 9999;
  width: 100%;
}

.logo {
  background-image: url("../../images/dark/template/logo.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 206px;
  height: 29px;
  display: block;
  font-size: 0;
}

.logo.bs {
  background-image: url("../../images/dark/template/bs.png");
}

.logo.hv {
  background-image: url("../../images/dark/template/hv.png");
}

.logo.sb {
  background-image: url("../../images/dark/template/sb.png");
}

.logo.bb {
  background-image: url("../../images/dark/template/bb.png");
}

.logo.ch {
  background-image: url("../../images/dark/template/ch.png");
}

.logo.o2o {
  background-image: url("../../images/dark/template/o2o.png");
  width: 250px;
}

.logo.dbs, .logo.dbsuk {
  background-image: url("../../images/dark/template/dbs.png");
  width: 250px;
}

.logo.dot-blocks {
  background-image: url("../../images/dark/template/dot-blocks.png");
  width: 161px;
  background-size: contain;
  margin-top: 1px;
  @media (max-width: 340px) {
    width: 130px;
  }
}

.logo.cloud-hatch {
  background-image: url("../../images/dark/template/cloud-hatch.png");
  width: 172px;
  background-size: contain;
  margin-top: 1px;
  @media (max-width: 340px) {
    width: 140px;
  }
}

.logo.signal-grove {
  background-image: url("../../images/dark/template/signal-grove.png");
  width: 181px;
  background-size: contain;
  margin-top: 1px;
  @media (max-width: 340px) {
    width: 150px;
  }
}

.logo.findzer {
  background-image: url("../../images/dark/template/findzer.png");
  width: 129px;
  background-size: contain;
  margin-top: 1px;
  @media (max-width: 340px) {
    width: 100px;
  }
}

.logo.dm {
  background-image: url("../../images/dark/template/dm.png");
  width: 129px;
}

.logo.hy {
  background-image: url("../../images/dark/template/hy.png");
  width: 250px;
}

.logo.db {
  background-image: url("../../images/dark/template/db.png");
  width: 250px;
}

.logo.bsw {
  background-image: url("../../images/dark/template/bsw.png");
  width: 250px;
}

.logo.signal, .logo.sg {
  background-image: url("../../images/dark/template/sg.png");
}

.logo.vx {
  background-image: url("../../images/dark/template/vx.png");
}

.logo.patchbit {
  background-image: url("../../images/dark/template/pb.png");
  width: 250px;
}

/*HEADER & FOOTER*/
/*MAP*/
.map-wrap * {
  position: initial;
}

#map-canvas {
  height: calc(100% - 93px);
  margin: 0;
  padding: 0;
}

/*MAP*/
.content ul li {
  display: none;
}

.fancy1 .error, #paypalContentInner .error {
  display: none !important;
}

.box-wrap {
  max-width: 570px;
  background-color: #fff;
  padding: 0 2rem;
  min-height: 370px;
}

.fancy-container {
  -webkit-transition-property: heigth, top;
  transition-property: heigth, top;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
}

.minified .box-wrap {
  min-height: auto;
}

.toggle-wrap span {
  display: none;
}

.minified .toggle-wrap span {
  display: inline-block;
}

.toggle-wrap i {
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
}

.minified .toggle-wrap i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*BTNS*/
.btn {
  border-radius: 3rem;
}

.btn-dark, .btn-dark:hover, .btn-dark:focus {
  background-color: #000000;
  color: #fff;
}

.btn-energized, .btn-energized:hover, .btn-energized:focus {
  background-color: $primary-tone;
  color: #fff;
}

.btn-primary {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $primary-tone;
  color: $white;
  font-size: 1.6rem;
  font-weight: $fw-bold;
  border: 1px solid $primary-tone !important;
  line-height: 1.2em;
  @media (max-width: 578px) {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #F8BB79 !important;
  }

  &:focus, &:active, &:active:focus, &:hover,
  &[disabled]:focus, &[disabled]:active, &[disabled]:active:focus, &[disabled]:hover {
    background-color: $primary-tone-muted;
  }
}

.btn-light {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $white;
  color: $black;
  font-size: 1.6rem;
  font-weight: $fw-bold;
  border: 1px solid $white !important;
  line-height: 1.2em;

  &:focus, &:active, &:hover {
    background-color: $white;
  }
}

.btn-clear {
  border-radius: 3rem;
  border: 0.5px solid #DDDDDE;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(29, 29, 31, 0.14);
  color: $grey-900;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: .77em;
  padding: 1.6rem 3rem;
  min-width: 25rem;
}

/*BTNS*/
/*LISTS*/
.steps {
  border-top: 1px solid transparent;
  text-align: center;
  overflow: hidden;
  min-height: 245px;
  width: 100%;
  list-style: none;
}

.steps li {
  position: absolute;
  top: 0;
  left: 999px;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.steps li:before {
  content: "";
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 134px;
  display: block;
}

.steps i {
  margin-right: 1rem;
  color: $primary-tone;
}

.steps.simple-list {
  margin: 2rem auto 1rem;
}

.steps.simple-list li:before {
  display: none;
}

.capabilities-list .step-0:before {
  background-image: url(../../images/dark/icon/mobile.png);
}

.capabilities-list .step-1:before {
  background-image: url(../../images/dark/icon/load.png);
}

.capabilities-list .step-2:before {
  background-image: url(../../images/dark/icon/check.png);
}

.search-list .step-0:before {
  background-image: url(../../images/dark/icon/satellite.png);
}

.search-list .step-1:before {
  background-image: url(../../images/dark/icon/antenna.png);
}

.search-list .step-2:before {
  background-image: url(../../images/dark/icon/map-marker.png);
}

.search-list .step-3:before {
  background-image: url(../../images/dark/icon/map-point.png);
}

.search-list .step-4:before {
  background-image: url(../../images/dark/icon/check.png);
}

.search-list .step-4:before {
  background-image: url(../../images/dark/icon/check.png);
}

.promo-list li:before {
  background-image: url(../../images/dark/icon/promo.png);
}

/*LISTS*/
/*BACKGROUNDS*/
.bg-dark {
  background-color: #000;
}

.bg-orange {
  background-color: $primary-tone;
}

.bg-energized {
  background-color: $primary-tone;
}

.bg-light {
  background-color: #fff;
}

.bg-dark * {
  color: #fff;
}

.bg-light * {
  color: #000;
}

/*BACKGROUNDS*/
/*COLORS*/
.c-energized {
  color: $primary-tone;
}

.c-light {
  color: #fff;
}

/*COLORS*/
/*POSITIONS*/
.p-absolute {
  position: absolute;
}

.xy-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

/*POSITIONS*/
.toggle-wrap {
  font-size: 2rem;
  margin-left: -2rem;
  margin-right: -2rem;
  cursor: pointer;
  padding: .5rem 2rem;

  i {
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -1rem;
  }
}

.paypal-wrap {
  width: 570px;
}

.do-operation {
  vertical-align: middle;
  margin-top: -6px;
}

.paypalForm {
  display: none;
}

.bg-dark input[type=tel] {
  color: #000 !important;
}

.form-control {
  border: 1px solid $grey-400;
  font-size: 2rem;
  height: 35px;
  @media (min-width: 578px) {
    font-size: 1.2rem;
  }
}

@-moz-keyframes pulsate {
  from {
    -moz-transform: scale(0.25);
    opacity: 1.0;
  }
  95% {
    -moz-transform: scale(1.3);
    opacity: 0;
  }
  to {
    -moz-transform: scale(0.3);
    opacity: 0;
  }
}

@-webkit-keyframes pulsate {
  from {
    -webkit-transform: scale(0.25);
    opacity: 1.0;
  }
  95% {
    -webkit-transform: scale(1.3);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(0.3);
    opacity: 0;
  }
}

/* get the container that's just outside the marker image,
        which just happens to have our Marker title in it */
#map-canvas div[title="CellTrack"] {
  -moz-animation: pulsate 1.5s ease-in-out infinite;
  -webkit-animation: pulsate 1.5s ease-in-out infinite;
  border: 1pt solid #fff;
  /* make a circle */
  -moz-border-radius: 51px;
  -webkit-border-radius: 51px;
  border-radius: 51px;
  /* multiply the shadows, inside and outside the circle */
  -moz-box-shadow: inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
  -webkit-box-shadow: inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
  box-shadow: inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
  /* set the ring's new dimension and re-center it */
  height: 51px !important;
  margin: -18px 0 0 -18px;
  width: 51px !important;
}

/* hide the superfluous marker image since it would expand and shrink with its containing element */
/*	#map-canvas div[style*="987654"][title] img {*/
#map-canvas div[title="CellTrack"] img {
  display: none;
}

/* compensate for iPhone and Android devices with high DPI, add iPad media query */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (device-width: 768px) {
  #map-canvas div[title="CellTrack"] {
    margin: -17px 0 0 -17px;
  }
}

.legal-small {
  font-size: 60%;
}

/*GRID*/
[class$='-wrap'] {
  font-size: 0;
}

[class^='grid-'] {
  display: inline-block;
  vertical-align: top;
  margin: 0 .7%;
}

.grid-3-12 {
  width: 23.6%;
}

.grid-4-12 {
  width: 31.933%;
}

.grid-5-12 {
  width: 40.2666667%;
}

.grid-6-12 {
  width: 48.6%;
}

.grid-7-12 {
  width: 56.9333333%;
}

.grid-8-12 {
  width: 65.2666666%;
}

.grid-12-12 {
  width: 98.6%;
}

/*GRID*/
.btn-block {
  display: block !important;
  margin: 4% auto !important;
}

.btn-red {
  background-color: #ff2626;
  border: medium none;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 1.2rem;
  margin: 4% auto;
  padding: 4% 1%;
  text-align: center;
  width: 100%;
}

p, li {
  font-size: 1.5rem;
  color: #000;
  @media (max-width: 321px) {
    font-size: 1.2rem;
  }
}

.ico-help {
  font-size: 2rem;
  position: absolute;
  right: 5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #f00 !important;
}

.overlay-wrap {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 9999;
  cursor: pointer;
}

.custom-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 81%;
  transform: translate(-50%, -55%);
}

.fancybox-wrap.fancybox-mobile.fancybox-opened {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
}

#wrapper {
  padding-right: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  z-index: 99999;

  &.toggled {
    padding-right: 280px;

    #sidebar-wrapper {
      width: 280px;
    }
  }
}

#sidebar-wrapper {
  z-index: 1000;
  right: 280px;
  width: 0;
  height: 100%;
  margin-right: -280px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  left: initial;
  border-radius: 10px 0 0 10px;
  box-shadow: -10px 0px 30px rgba(175, 119, 111, 0.21);

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebar-brand {
  font-size: 1.4rem;
  line-height: 1.2em;
  border-bottom: 1px solid $orange;
  color: $grey;
  text-align: center;
  letter-spacing: .2rem;
  margin: 1rem auto;
  padding: 1rem 0;
  width: auto;
  display: inline-block;
}

.hamburger {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
  display: block;
  width: 32px;
  height: 32px;
  margin-left: 15px;
  background: transparent;
  border: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &.is-closed {
    &:before {
      content: '';
      display: block;
      width: 100px;
      font-size: 14px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-transition: all .35s ease-in-out;
    }

    &:hover:before {
      opacity: 1;
      display: block;
      -webkit-transform: translate3d(-100px, 0, 0);
      -webkit-transition: all .35s ease-in-out;
    }

    & .hamb-top,
    & .hamb-middle,
    & .hamb-bottom {
      background-color: $primary-tone;
    }

    & .hamb-top {
      top: 5px;
      -webkit-transition: all .35s ease-in-out;
    }

    & .hamb-middle {
      top: 50%;
      margin-top: -2px;
    }

    & .hamb-bottom {
      bottom: 5px;
      -webkit-transition: all .35s ease-in-out;
    }

    &:hover .hamb-top {
      top: 0;
      -webkit-transition: all .35s ease-in-out;
    }

    &:hover .hamb-bottom {
      bottom: 0;
      -webkit-transition: all .35s ease-in-out;
    }
  }

  &.is-open {
    & .hamb-top,
    & .hamb-middle,
    & .hamb-bottom {
      background-color: $primary-tone;
    }

    & .hamb-top,
    & .hamb-bottom {
      top: 50%;
      margin-top: -2px;
    }

    & .hamb-top {
      -webkit-transform: rotate(45deg);
      -webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
    }

    & .hamb-middle {
      display: none;
    }

    & .hamb-bottom {
      -webkit-transform: rotate(-45deg);
      -webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
    }

    &:before {
      content: '';
      display: block;
      width: 100px;
      font-size: 14px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-transition: all .35s ease-in-out;
    }

    &:hover:before {
      opacity: 1;
      display: block;
      -webkit-transform: translate3d(-100px, 0, 0);
      -webkit-transition: all .35s ease-in-out;
    }
  }

  &.is-closed .hamb-top,
  &.is-closed .hamb-middle,
  &.is-closed .hamb-bottom,
  &.is-open .hamb-top,
  &.is-open .hamb-middle,
  &.is-open .hamb-bottom {
    position: absolute;
    left: 0;
    height: 4px;
    width: 100%;
  }
}

.fw-regular {
  font-weight: 400;
}

.fw-bold {
  font-weight: $fw-bold;
}

.txt-dark {
  color: $black;
}

.txt-muted {
  color: $grey-400;
}

.txt-green {
  color: $green;
}

.txt-orange {
  color: $orange-600;
}

.txt-blue {
  color: $blue;
}

.txt-red {
  color: #ff5252;
}

.main-wrap {
  padding-top: 5vh;
  padding-bottom: 2vh;
  @media (max-width: 578px) {
    &.section-voice-wrap {
      .container {
        width: 100%;
      }
    }
  }

  .highlight {
    color: $primary-tone;
  }

  h1 {
    font-size: 3.5rem;
    color: $primary-tone;
    @media (min-width: 578px) {
      font-size: 5.8rem;
    }

    &.muted {
      color: $muted;
    }
  }

  h3 {
    font-size: 1.8rem;
    color: $black;
  }

  p {
    font-size: 1.6rem;
    color: $muted;

    &.txt-green {
      color: $green;
    }

    &.txt-orange {
      color: $orange-600;
    }

    &.txt-blue {
      color: $blue;
    }

    &.txt-red {
      color: #ff5252;
    }
  }

  p.fs-small {
    font-size: 1.2rem;
    color: $grey-900;
    font-weight: 500;
  }

  a.text-primary {
    color: #2E8AF5;
    text-decoration: underline;

    &:hover, &:focus, &:active {
      color: #2E8AF5;
      text-decoration: underline;
    }
  }

  .nav-pills-wrap {
    width: 100%;
    overflow: hidden;
  }

  .nav-pills {
    @media (max-width: 768px) {
      display: flex;
      overflow-x: scroll;
      & > li {
        float: none;
      }
    }

    & > li {
      font-weight: $fw-bold;
      text-align: center;
      margin-bottom: 1.5rem;

      a {
        font-size: 2.1rem;
      }

      & > a:hover, & > a:focus {
        background-color: lighten($primary-tone, 43);
        color: $orange;
      }

      &.active {
        & > a,
        & > a:hover,
        & > a:focus {
          background-color: lighten($primary-tone, 43);
          color: $orange;
        }
      }
    }
  }

  .questions-list {
    li {
      border-radius: 2rem;
      border: 1px solid #DDDDDE;
      background: $white;
      box-shadow: 0px 0.5px 2px 0px rgba(25, 33, 61, 0.10);
      padding: 2rem 2rem 2rem 2.4rem;
      margin-bottom: 1rem;

      &.active, &:hover {
        border: 1px solid #F17F2C;
      }

      .question-wrap {
        display: flex;
        justify-content: space-between;
        padding: 0;

        h3 {
          margin: 0;
        }

        .btn-square {
          width: 1.5rem;
        }

        .close {
          position: relative;
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          overflow: hidden;
          transition: transform 1s;
          opacity: 1;
        }

        .close::before,
        .close::after {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          top: 50%;
          left: 0;
          background: $primary-tone;
          margin-top: -1px;
        }

        .close::before {
          transform: rotate(0deg);
        }

        .close::after {
          transform: rotate(90deg);
        }

        &[aria-expanded='true'] .btn-square .close {
          transform: rotate(-135deg);

          &:before, &:after {
            background: $black;
          }
        }
      }

      &[aria-expanded='true'] .btn-square .close {
        transform: rotate(-135deg);

        &:before, &:after {
          background: $black;
        }
      }

      .answer-content-wrap {
        margin-top: 1rem;

        p {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &.home-wrap {
    .questions-list {
      li {
        border: none;
        background: #FFFFFF;
        box-shadow: 0px 4px 50px rgba(214, 215, 217, 0.3);
        border-radius: 4px;
        padding: 1.5rem 3rem;
        margin-bottom: .5rem;

        .answer-content-wrap {
          border-left: none;
          margin-left: 0;
          padding: 1rem 0;
        }
      }
    }
  }

  .checked-list {
    li {
      padding-left: 2rem;
      position: relative;
      font-size: 1.6rem;
      color: $grey;
      margin-bottom: 1.5rem;

      &:before {
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        background-image: url('../../images/ico/check.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        position: absolute;
        left: .2rem;
        top: .3rem;
      }
    }
  }

  .card-wrap {
    border-radius: .5rem;
    box-shadow: 0px 5px 30px rgba(175, 119, 111, 0.21);
    overflow: hidden;

    &:before {
      content: "";
      height: 3rem;
      width: 100%;
      display: block;
      background-color: $black;
    }

    .card-content {
      padding: 2rem 3rem;

      .price {
        font-size: 3.7rem;
        color: $black;
        font-weight: $fw-bold;
        margin: 0;
      }

      p small {
        font-size: .625em;
      }
    }
  }

  .btn {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  @media (max-width: 578px) {
    .modal-footer .btn + .btn {
      margin-left: 0;
      margin-bottom: 0;
    }
    .success-wrap .btn-warning,
    .modal-footer .btn-warning {
      line-height: 1.4em;
    }
  }
  @at-root .main-wrap-map .btn-default,
  .btn-default {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.6rem;
    font-weight: $fw-bold;
    @media (max-width: 578px) {
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    }
  }
  .btn-primary {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $primary-tone;
    color: $white;
    font-size: 1.6rem;
    font-weight: $fw-bold;
    border: 1px solid $primary-tone !important;
    line-height: 1.2em;
    @media (max-width: 578px) {
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
      border: 1px solid #F8BB79 !important;
    }

    &:focus, &:active, &:hover {
      background-color: $primary-tone-muted;
    }
  }

  .btn-warning {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $primary-tone;
    color: $white;
    font-size: 1.6rem;
    font-weight: $fw-bold;
    border: 1px solid $primary-tone !important;
    @media (max-width: 578px) {
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
      border: 1px solid #F8BB79 !important;
    }
    line-height: 1.2em;

    &:focus, &:active, &:hover {
      background-color: $primary-tone-muted;
    }
  }

  .btn-outline-primary {
    border: 1px solid $primary-tone;
    color: $primary-tone;
    font-size: 1.8rem;
    font-weight: $fw-bold;
  }

  .txt-primary-vivid {
    color: $primary-tone-vivid;
  }

  .index-wrap {
    li {
      display: inline-block;
    }

    a {
      color: #E15D15;
      font-weight: $fw-bold;
      background-color: #FEF7EE;
      padding: 0.7rem 2rem;
      border-radius: 3rem;
      margin-right: 0.5rem;
      cursor: pointer;
      line-height: 1em;
      display: block;

      &:hover, &.active {
        color: $white;
        background-color: $orange-600;
      }
    }
  }

  .basic-list {
    padding: 2.5rem 2rem;
    background-color: lighten($primary-tone, 43);
    border-radius: .8rem;

    li {
      margin-bottom: 1rem;
    }

    a {
      color: $grey;
      font-weight: $fw-bold;

      &:hover, &.active {
        color: $primary-tone;
      }
    }
  }

  @media (min-width: 578px) {
    .tc-nav {
      position: sticky;
      top: 1rem;
    }
  }

  &.home-wrap {
    padding-top: 0;
    padding-bottom: 0;

    h2 {
      font-size: 2.5rem;
      @media (min-width: 578px) {
        font-size: 3.7rem;
      }
    }
  }

  .form-inline {
    div.d-flex {
      flex: 3 1 auto;
      @media (max-width: 578px) {
        flex-direction: column;
      }

      .iti {
        width: 100%;
      }

      .telephone-type {
        width: 100%;
      }
    }
  }

  .top-wrap {
    background: linear-gradient(133deg, #FFF 1.28%, #FFBB71 78.21%);
    overflow: hidden;
    border-radius: 0 0 50px 50px;

    .top-container {
      background-image: url("../../images/template/map.svg");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      @media (min-width: 578px) {
        min-height: 657px;
      }
    }

    @media (max-width: 578px) {
      padding-top: 3rem;
      .btn {
        display: block;
      }
    }

    h1, p {
      color: #2A313D;
    }

    p {
      font-size: 1.8rem;
    }

    h1 {
      font-size: 3.3rem;

      &:first-line {
        color: $orange;
      }

      @media (min-width: 578px) {
        font-size: 5.4rem;
      }
    }

    label {
      font-size: 1.6rem;
    }

    .help-block {
      font-size: 1.2rem;
      color: #B4B4B4;
      text-align: right;
    }

    .img-wrap {
      picture {
        max-width: 70%;
        @media (min-width: 578px) {
          max-width: 250px;
        }
        display: block;
        margin: auto;
        left: 15%;

        img {
          max-width: 100%;
        }
      }

      margin: 5rem 0;
      @media (min-width: 578px) {
        //margin-top: -10vh;
        //top: 13vh;
      }
    }

    .result-example-wrap {
      max-width: 60%;
      position: absolute;
      left: 3%;
      top: 15%;
      @media (min-width: 578px) {
        max-width: 254px;
        left: -5%;
      }
    }
  }

  .two-items {
    button {
      flex: 1 1 0px;
    }
  }

  .requirements-wrap {
    .ico-wrap {
      flex: 1 1 0;

      img {
        margin: auto;
      }
    }

    .text-wrap {
      flex: 7 1 0;
      padding: 0 .5rem;
      display: flex;
      align-items: center;

      h3 {
        font-weight: 600;
        color: $black;
        margin: 0;
      }
    }
  }

  .features-wrap {
    .d-flex {
      align-items: center;
    }

    .ico-wrap {
      flex: 1 1 0;
    }

    .text-wrap {
      flex: 4 1 0;
      padding: 0 1.5rem;
    }
  }

  .how-it-works-wrap {
    @media (max-width: 578px) {
      padding-left: 3.5rem;
    }

    &:before {
      height: 71%;
      width: 4.3rem;
      position: absolute;
      left: 0;
      top: 0%;
      background-image: url("../../images/home/how/steps/linea.svg");
      background-size: 100% auto;
      background-position: center 2rem;
      background-repeat: repeat-y;
      content: "";
      @media (min-width: 578px) {
        width: 5rem;
        left: 50%;
        transform: translateX(-50%);
        background-size: 100% auto;
        background-position: center;
        height: 69%;
        top: 15%;
      }
    }

    .d-flex {
      align-items: center;
      flex-direction: column;
      @media (min-width: 578px) {
        flex-direction: row;
      }
    }

    .img-wrap {
      flex: 1 1 0;
      @media (max-width: 578px) {
        max-width: 50vw;
        margin: auto;
      }
    }

    .text-wrap {
      flex: 1 1 0;
      padding: 0 1.5rem;
    }

    .p-x {
      padding: 0 2rem;
    }

    @media (min-width: 578px) {
      .p-right {
        padding-right: 4rem;
      }
      .p-left {
        padding-left: 4rem;
      }
      .p-x {
        padding: 0 5rem;
      }
      .move-left {
        left: -3rem;
      }
      .move-right {
        right: -3rem;
      }
    }

    .s-wrap {
      @media (max-width: 578px) {
        &.s-1-wrap, &.s-3-wrap {
          flex-direction: column-reverse;
        }
      }

      &:before {
        content: "";
        background-repeat: no-repeat;
        position: absolute;
        background-size: contain;
        top: 50%;
        left: -22.43vw;
        width: 22.43vw;
        height: 32.36vw;
        transform: translateY(-50%) scale(.7);
        z-index: -1;
        display: none;
        @media (min-width: 578px) {
          display: block;
        }
      }

      &.s-1-wrap:before {
        background-image: url("../../images/home/how/1.svg");
      }

      &.s-2-wrap:before {
        background-image: url("../../images/home/how/2.svg");
        left: initial;
        right: -22.43vw;
      }

      &.s-3-wrap:before {
        background-image: url("../../images/home/how/3.svg");
      }
    }
  }

  @media (max-width: 578px) {
    .xs-flex-column {
      .d-flex {
        flex-direction: column;

        .ico-wrap {
          width: 25%;
          margin: auto;
        }
      }
    }
  }
  @media (min-width: 578px) {
    .text-center {
      .img-wrap {
        img {
          margin: auto;
        }
      }
    }
  }

  .simple-form-style {
    .form-group {
      margin-bottom: 1.5rem;
    }

    label {
      font-size: 1.6rem;
      font-weight: $fw-bold;
    }

    .form-control {
      &:focus {
        background-color: #F8EDE4;
        color: $primary-tone;
      }
    }
  }

  .scroll-wrap {
    height: 20rem;
    padding: 1rem;
    background-color: #F8EDE4;
    border: 1px solid $grey;
    border-radius: .5rem;
    overflow-y: scroll;
  }

  .steps-wraps {
    width: 65rem;
    max-width: 90%;
    margin: 4rem auto;
    height: 0.7rem;
    background-color: #EEEEEE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .step {
      background-color: #EEEEEE;
      height: 5rem;
      width: 5rem;
      border-radius: 2.5rem;
      padding: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      color: $grey;
      font-size: 1rem;
      font-weight: bold;

      span {
        position: absolute;
        bottom: -1.5rem;
        color: $grey;
      }

      &.active {
        background-color: $orange;
        color: $white;

        span {
          color: $orange;
        }
      }

      &.done {
        background-color: $orange;
        color: $white;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 0%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $orange;
    }

    &.steps-done-1::before {
      width: 50%;
    }

    &.steps-done-2::before {
      width: 100%;
    }
  }
}

.footer-nav-wrap {
  background-color: #28292B;
  color: $white;
  padding: 3rem 0;

  .logo {
    margin-left: 0;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  p, a {
    color: $white;
  }

  .btn-primary {
    background-color: $primary-tone;
    color: $white;
    font-size: 1.8rem;
    font-weight: $fw-bold;
    border: none;
  }

  ul {
    li {
      margin-bottom: 1rem;
    }
  }
}

.border-separate {
  border-collapse: separate;
}

.table-responsive {
  border-radius: 1.6rem;
  @media (min-width: 578px) {
    -webkit-box-shadow: 0 2px 20px 0 rgba(241, 127, 44, .2);
    box-shadow: 0 2px 20px 0 rgba(241, 127, 44, .2);
  }

  .table-simple-style {
    @media (max-width: 578px) {
      table-layout: fixed;
      .no-results-row {
        p {
          white-space: normal;
        }
      }
    }
    font-size: 1.4rem;
    color: $grey;
    margin-bottom: 0;
    border-radius: 0;
    background-color: $white;

    & > thead {
      z-index: 9999;
    }

    & > thead > tr > th {
      position: sticky;
      top: 0;
      background-color: $white;
    }

    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th,
    & > thead > tr > td,
    & > tbody > tr > td,
    & > tfoot > tr > td {
      padding: 1rem 1.5rem;
    }

    thead {
      th {
        color: $black;

        .ico-wrap {
          display: inline-block;
          vertical-align: text-top;
          margin-right: .5rem;
        }
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: #F8F8F8;
      }
    }

    & > tbody > tr > td {
      border: none;
    }

    tfoot {
      text-align: right;
    }
  }
}

.list-simple {
  font-size: 1.6rem;
  margin: 1rem 0;
  padding-left: 1.5rem;

  li {
    color: $grey;
    margin-bottom: .5rem;
  }
}

.cookie-banner {
  opacity: 0;
  animation: initial-hide 1s ease-out 1.5s forwards;
  z-index: 10010;
  position: fixed;
  bottom: 1rem;
  width: 95%;
  max-width: 700px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: 1.5rem;
  box-shadow: 0px 2.3rem 6rem rgba(184, 114, 74, .8);
  padding: 1rem;
  text-align: center;

  .img-wrap {
    width: 2.5rem;
    vertical-align: middle;
    margin-right: 1rem;
  }

  .ico-wrap {
    width: 2.5rem;
    vertical-align: middle;
    display: inline-block;
    margin-left: 2rem;
    @media (max-width: 578px) {
      width: 2rem;
      vertical-align: top;
      margin-left: .5rem;
    }

    svg {
      fill: $orange;
      width: 100%;
      height: 100%;
    }
  }

  p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    color: $grey;
    @media (max-width: 578px) {
      max-width: calc(100% - 4rem);
      text-align: left;
    }

    a {
      color: #2FABF7;
      text-decoration: underline;
    }
  }

  &.hidden {
    display: none;
  }

  &.cookie-fade {
    opacity: 0;
    animation-fill-mode: none
  }
}

/* animation keyframes that animates the banner in onload */
@keyframes initial-hide {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.locate-wrap {
  background-image: url('../../images/lct/bg.svg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 95vh;
  @media (min-width: 578px) {
    max-height: 95vh;
    flex-direction: row;
  }

  .h-100 {
    height: 100%;
  }

  .main-wrap {
    &.align-items-start {
      align-items: start;
    }

    width: 100%;
    min-height: 61vh;
    display: flex;
    align-items: center;
    overflow-y: scroll;

    &.top-align {
      align-items: start;
    }
  }

  .btn-edit {
    background-image: url('../../images/lct/edit.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.5rem;
    height: 2.5rem;
    display: inline-block;
    vertical-align: unset;
  }

  .main-wrap-map {
    width: 100%;
    min-height: 93vh;
    @media (max-width: 578px) {
      & .h-100 {
        height: 93vh;
      }
    }

    #map-canvas {
      height: 100%;
    }
  }
}

.navbar-side {
  background-color: $white;
  box-shadow: 0px 4px 10px 0px rgba(241, 127, 44, 0.25);
  padding: 1.2rem 0;
  z-index: 99;
  @media (min-width: 578px) {
    padding: 1.5rem 0;
    .navbar-collapse {
      height: 100% !important;
    }
  }

  .navbar-side-toggler-wrap {
    display: none;

    .section-title {
      flex-grow: 1;

      img {
        max-width: 2rem;
      }
    }
  }

  @at-root  &.is-open {
    .expand-nav-toggler {
      img {
        transform: rotate(0deg);
      }
    }
  }
  .expand-nav-toggler {
    display: block;
    text-align: right;
    border-bottom: .5px solid #C8C8C8;
    padding: 0 0 0.5rem;

    img {
      transform: rotate(180deg);
    }
  }

  .add-ico {
    padding-left: 2.5rem;
    background-size: auto 70%;
    background-position: left 4px;
    background-repeat: no-repeat;

    &.ico-phone {
      background-image: url('../../images/lct/ico/search.svg');
    }

    &.ico-trial-started {
      background-image: url('../../images/lct/ico/search.svg');
    }

    &.ico-history {
      background-image: url('../../images/lct/ico/history.svg');
    }

    &.ico-results {
      background-image: url('../../images/lct/ico/phone.svg');
    }

    &.ico-reverse {
      background-image: url('../../images/lct/ico/phone.svg');
    }

    &.ico-voice, &.ico-voice-history {
      background-image: url('../../images/lct/ico/voice.svg');
    }

    &.ico-faqs {
      background-image: url('../../images/lct/ico/help.svg');
    }

    &.ico-notifications {
      background-image: url('../../images/lct/ico/notification.svg');
    }

    &.ico-settings {
      background-image: url('../../images/lct/ico/settings.svg');
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    &.at-bottom {
      border-top: .5px solid #C8C8C8;

      .nav-item:first-child {
        margin-top: 3rem;
      }
    }

    @media (min-width: 578px) {
      &.at-bottom {
        position: absolute;
        bottom: 0;
        width: calc(100% - 3rem);
      }
    }

    .nav-item {
      margin-bottom: 1.5rem;

      .nav-link {
        display: flex;
        align-items: center;
        color: $grey-400;
        padding: .7rem .5rem;
        font-size: 1.9rem;
        font-weight: 400;

        small {
          font-size: 1.4rem;
          font-weight: 300;
        }

        span {
          margin-left: 2rem;
          text-align: left;
        }

        img {
          @media (min-width: 578px) {
            width: 3rem;
          }
        }

        img:not(.no-filter) {
          filter: invert(69%) sepia(1%) saturate(0%) hue-rotate(170deg) brightness(90%) contrast(83%);
        }

        &:hover {
          color: #545454;

          img:not(.no-filter) {
            filter: invert(37%) sepia(0%) saturate(90%) hue-rotate(241deg) brightness(86%) contrast(94%);
          }
        }

        &.active {
          background-color: #FEF7EE;
          border-radius: 3rem;
          color: $primary-tone;
          font-weight: 700;
          box-shadow: 0px 1px 4px 0px rgba(241, 127, 44, 0.20);

          img:not(.no-filter) {
            filter: invert(56%) sepia(47%) saturate(739%) hue-rotate(341deg) brightness(93%) contrast(102%);
          }

          &:hover {
            color: $primary-tone;
          }
        }
      }

      &.nav-item-user {
        .nav-link {
          &:hover {
            color: $grey-400;
          }
        }
      }

      .badge {
        position: absolute;
        top: 0rem;
        left: 3rem;
        background-color: $orange;
      }

      &.nav-item-user {
        span {
          line-height: 1em;
        }
      }
    }
  }

  @media (min-width: 578px) {
    .nav-link {
      span {
        display: none;
      }
    }
    &.is-open {
      width: 315px;

      .nav-link {
        span {
          display: block;
        }
      }
    }
  }
  @media (max-width: 578px) {
    .tooltip {
      display: none !important;
    }
    .navbar-side-toggler-wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;

      & > span {
        font-size: 1.9rem;
        color: $black;
        margin-left: 1.5rem;
        filter: invert(65%) sepia(25%) saturate(6804%) hue-rotate(344deg) brightness(101%) contrast(89%);
      }

      .navbar-toggler {
        margin-right: 1.5rem;
        width: 2.8rem;
        height: 2.8rem;
        background: transparent;
        position: relative;
        border: none;
        border-radius: 2.8rem;

        &[aria-expanded="true"] {
          background: transparent;
        }

        &:before, &:after {
          content: "";
          display: block;
          width: 1.5rem;
          height: .2rem;
          background-color: #DB733B;
          position: absolute;
          top: 45%;
          transition: transform .5s;
        }

        &:before {
          left: 38%;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          transform: rotate(-45deg);
        }

        &:after {
          right: 43%;
          transform: rotate(45deg);
        }

        &[aria-expanded="true"]:before,
        &[aria-expanded="true"]:after {
          background-color: $black;
        }

        &[aria-expanded="true"]:before {
          transform: rotate(45deg);
          top: 50%;
        }

        &[aria-expanded="true"]:after {
          transform: rotate(-45deg);
          top: 50%;
        }
      }
    }
    .navbar-collapse {
      background-color: white;
      position: absolute;
      width: 100%;
      height: calc(100vh - 101px);
      top: 48px;

      &:before {
        position: fixed;
        top: 101px;
        left: 15px;
        height: 0.5px;
        background-color: #999999;
        width: calc(100% - 30px);
        content: "";
        z-index: 99999;
      }

      ul {
        padding-top: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;

        li {
          flex: 0 1 100%; /*grow | shrink | basis */
          text-align: center;
          padding: 0 1rem;

          a {
            width: auto;

            img {
              max-width: 3rem;
            }

            span {
              display: block;
            }
          }
        }
      }
    }
    .nav-link {
      span {
        display: block;
      }
    }
  }
}

.phone-input-wrap {
  .img-wrap {
    width: 12%;
    margin: auto;

    img {
      box-shadow: -5px 8px 11px rgba(181, 113, 113, 0.3);
      border-radius: 1.3rem;
    }
  }
}

.message-input-wrap {
  .img-wrap {
    width: 6%;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    font-size: 3.2rem;
  }
}

.form-input-message-wrap {
  label {
    font-size: 1.6rem;
    text-align: left;
    display: block;
  }

  textarea.form-control {
    padding: 1rem;
    font-size: 1.6rem;
    border: 1px solid #666666;
    width: 100%;
  }

  button {
    font-size: 2.2rem;
  }
}

.alert-warning {
  p {
    color: $orange;

    small {
      font-size: 80%;
    }
  }
}

.modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal.fade .modal-dialog {
  transform: translate(0, -100%);
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-content {
  .modal-header {
    background-color: $black;
  }
}

.success-wrap {
  @media (max-width: 578px) {
    width: calc(100vw - 30px);
  }

  .img-wrap {
    width: 39%;
    margin: auto;
  }

  a {
    font-size: 1.6rem;
  }
}

.phone-reverse-input-wrap {
  .img-wrap {
    width: 39%;
    margin: auto;
  }
}

.voice-input-wrap {
  .img-wrap {
    width: 33%;
    margin: auto;
  }
}

.results-wrap {
  h1 {
    font-size: 3.6rem;
    font-weight: 600;
  }
}

.settings-wrap {
  h1, .h1 {
    font-size: 4rem;
    @media (min-width: 578px) {
      font-size: 6.4rem;
    }
  }

  h2, .h2 {
    font-size: 3.2rem;
    color: $black;
    @media (min-width: 578px) {
      font-size: 3.6rem;
    }
  }

  h3, .h3 {
    font-size: 2rem;
    @media (min-width: 578px) {
      font-size: 2.4rem;
    }
  }

  h4, .h4 {
    font-size: 1.6rem;
    @media (min-width: 578px) {
      font-size: 2rem;
    }
  }

  p, label {
    font-size: 1.6rem;
  }

  hr {
    height: .5px;
    background-color: #666666;
    border: none;
    margin: 3rem 0;
  }
}

.history-col-wrap {
  position: absolute;
  z-index: 10;
  bottom: 0;
  background-color: $white;
  border-radius: 0;
  transition-duration: .5s;

  .popover-trigger {
    pointer-events: none;
    cursor: pointer;
  }

  &.visible {
    .popover-trigger {
      pointer-events: all;
    }
  }

  @media (min-width: 578px) {
    min-width: 390px;
    bottom: initial;
    left: -25%;
  }
  @media (max-width: 578px) {
    width: 100%;
    //left: -100%;
    border-radius: 3rem 3rem 0 0;
  }

  &.visible {
    left: 0;
    right: 0;

    .history-col-content {
      @media (max-width: 578px) {
        height: 75vh;
      }
    }

    .toggler-wrap {
      cursor: pointer;
      @media (max-width: 578px) {
        transform: translate(50%, -50%) rotate(-90deg);
      }
      @media (min-width: 578px) {
        transform: scaleX(1);
        right: 0;
      }
    }
  }

  .toggler-wrap {
    position: absolute;
    display: block;
    z-index: 1000;
    top: 0;
    right: 50%;
    transform: translate(35%, -50%) rotate(90deg);
    @media (min-width: 578px) {
      width: 3rem;
      top: 3rem;
      right: -6rem;
      transform: scaleX(-1);
      transform-origin: center center;
      margin-left: -2rem;
      margin-right: -2rem;
      cursor: pointer;
      padding: .5rem 2rem;
    }
  }

  .history-col-content {
    padding: 1.5rem;
    height: 35vh;
    overflow-y: hidden;
    overflow-x: hidden;
    transition-duration: .5s;

    &:focus-visible, &:focus {
      outline: none;
    }

    @media (max-width: 578px) {
      &.active {
        height: 75vh;

        .wrap-history-list {
          height: 72%;
        }
      }
    }
    @media (min-width: 578px) {
      height: 95vh;
    }

    h1 {
      font-size: 2.8rem;
      @media (max-width: 321px) {
        font-size: 2rem;
      }
    }

    .wrap-history-list {
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      overflow-y: scroll;
      overflow-x: hidden;
      @media (max-width: 578px) {
        width: 100%;
        height: 45%;
      }
      @media (min-width: 578px) {
        max-height: 83%;
      }

      &-item {
        font-weight: 400;
        border-radius: .8rem;
        background: $white;
        box-shadow: 0px 1px 4px 0px rgba(241, 127, 44, 0.20);
        padding: 1rem;
        margin-bottom: 1.5rem;
        @media (max-width: 366px) {
          p, h3 {
            font-size: 1.2rem;
          }
        }

        &.active {
          border: 0.5px solid #F17F2C;
        }

        .data-toggler {
          cursor: pointer;
        }

        //STATUS
        .justify-content-space-between {
          justify-content: space-between;
        }
      }
    }
  }
}

.form-input-tel-wrap {
  > * {
    flex: 1 0 auto;
  }

  @media (max-width: 578px) {
    flex-direction: column;
  }

  .iti {
    width: 100%;

    input {
      width: 100%
    }

    &__country-list {
      width: 90vw;
      max-width: 360px;
    }
  }

  .form-control {
    border-radius: .4rem 0 0 .4rem;
  }

  .btn {
    border-radius: 0 0.4rem 0.4rem 0;
  }
}

.notification-wrap {
  position: relative;
  box-shadow: 0px 5px 30px rgba(175, 119, 111, 0.21);
  border-radius: .5rem;

  .badge {
    position: absolute;
    top: -1em;
    right: 1rem;
    font-size: 1.2rem;
    background-color: #FF406F;
    border-radius: .4rem;
  }

  .ico-wrap {
    width: 2.5rem;
    margin-right: 1.5rem;
    display: flex;
  }

  .txt-wrap {
    color: $black;

    &[data-deep-link] {
      cursor: pointer;
    }

    span {
      color: $grey;
    }
  }
}

.line-decor {
  height: 1px;
  background-color: $orange;
  width: 100%;
  display: block;

  &::before {
    content: "+";
    position: absolute;
    width: 1.7rem;
    height: 1.7rem;
    left: -1rem;
    top: -.71em;
    color: $orange;
    font-size: 1.7rem;
    font-weight: bold;
  }
}

.select-plan-wrap {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &-input {
    background: $white;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 4px 8px rgba(158, 158, 158, 0.15);
    border-radius: .5rem;
    margin-bottom: 1.5rem;
    padding: 1.5rem 0;
    cursor: pointer;

    &.active {
      border-color: $orange;
    }
  }

  .plan-name {
    &:first-line {
      font-size: 1.8rem;
      color: $black;
    }

    font-size: 1.4rem;
    color: $grey;
    font-weight: bold;
  }

  .price-wrap {
    margin: 0;

    .price {
      font-weight: 700;
      font-size: 3.1rem;
      color: $black;
    }

    .currency {
      font-weight: 800;
      font-size: 1.6rem;
      color: $black;
      line-height: 1em;
      display: block;
    }

    .period {
      font-weight: 700;
      font-size: 1rem;
      color: $orange;
      line-height: 1em;
      display: block;
    }
  }
}

.txt-description {
  h2 {
    font-weight: 600;
    font-size: 5.8rem;
    color: $orange;
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    color: $black;
  }

  p {
    font-weight: 400;
    font-size: 1.8rem;
    color: $grey-900;
  }
}

p.txt-legend {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  text-align: center;
  color: $black;
  margin: 2rem auto;
}

.border-form-style {
  .form-group {
    margin-bottom: 1.5rem;
  }

  label {
    font-size: 1.6rem;
    font-weight: $fw-bold;
  }

  .form-control {
    border: 1px solid $grey;

    &:focus {
      color: $primary-tone;
    }
  }

  .help-block {
    font-size: .8rem;
  }
}

.card-content {
  .select-plan-wrap-input {
    box-shadow: 0px 4px 15px rgba(255, 176, 134, 0.3);
    padding: .5rem 0;

    p {
      margin: 0;
    }
  }
}

.form-inline {
  .form-bg {
    padding: .5rem;
    @media (min-width: 578px) {
      background-color: $white;
      border-radius: 12px;
      border: 1px solid #E2E2E2;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .form-errors-wrap {
    margin: .5rem;
  }

  .form-control {
    border: none;
    box-shadow: none;
    font-weight: 400;
    @media (min-width: 578px) {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .telephone-type {
    height: 100%;
    border-radius: 1.2rem;
    @media (max-width: 578px) {
      font-size: 1.6rem;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: 1px solid #E2E2E2;
    }
  }

  .btn-primary {
    font-size: 1.6rem;
    border-radius: .8rem;
    padding-top: .7rem;
    padding-bottom: .7rem;
    background-color: $primary-tone;
    @media (max-width: 578px) {
      font-size: 1.8rem;
      border-radius: 1.2rem;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    &:focus,
    &:hover {
      background-color: #ED6400;
    }
  }
}

.testimonial-wrap {
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(214, 215, 217, 0.5);
  border: 1px solid transparent;
  border-radius: 1.5rem;
  padding: 2.5rem 3rem;
  height: 100%;

  &:hover {
    border: 1px solid rgba(255, 163, 26, 0.5);
    box-shadow: 0px 10px 40px rgba(144, 146, 151, 0.33);
  }

  h3 {
    margin-top: .5rem;
  }

  h4 {
    color: $primary-tone-vivid;
  }

  .person-info {
    margin-left: 1rem;
  }
}

.ready-wrap {
  background: linear-gradient(30.84deg, #F17F2C -31.7%, #FFA31A 68.54%);
  margin-top: 18rem;

  h1 {
    color: $white;
    font-size: 4rem;
  }

  p {
    color: $white
  }

  .img-wrap {
    margin-top: -16rem;
  }

  @media (min-width: 578px) {
    .text-container-wrap {
      margin-top: -4rem;
    }
  }
}

.message-preview-wrap {
  position: relative;
  width: fit-content;
  margin: auto;

  .message-preview {
    background-color: $orange;
    max-width: 58%;
    right: 21%;
    position: absolute;
    top: 38%;
    border-radius: .5rem;
    padding: .5rem 1.5rem;

    &:before, &:after {
      content: "";
      position: absolute;
      bottom: -7px;
      height: 1.4rem;
    }

    &:before {
      right: -.7rem;
      width: 2rem;
      background-color: $orange;
      border-bottom-left-radius: 1.6rem 1.4rem;
    }

    &:after {
      right: -1.6rem;
      width: 1.6rem;
      background-color: $white;
      border-bottom-left-radius: 1rem;
    }
  }

  .message-preview-text {
    font-size: 1rem;
    color: $white;
    text-align: right;
    display: block;
  }
}

@media (max-width: 578px) {
  .paddle-frame.paddle-frame-overlay {
    height: 100vh !important;
  }
}

#floatingBarsG {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.blockG {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 45px;
  height: 45px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: blockg 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.blockG div:nth-child(1) {
  animation-delay: -0.45s;
}

.blockG div:nth-child(2) {
  animation-delay: -0.3s;
}

.blockG div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes blockg {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-loader, .p-ios {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, .5);
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
}

.p-ios {
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
}

.p-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-wrap {
  a {
    color: $orange;
  }
}

.language-selector {
  a {
    font-size: 2rem;
  }

  @media (max-width: 481px) {
    .dropdown-menu {
      left: initial;
      right: 0;
    }
  }
}

.info-list {
  height: inherit;
  border-radius: 24px 24px 0 0;
  box-shadow: 0 -2px 20px 0 rgba(219, 115, 59, 0.20);
  margin-top: 2rem;
  padding: 2rem 5px 0;
  list-style: none;

  .list-item-border {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    border: 1px solid transparent;

    p {
      color: transparent !important;
      margin: 0;
    }

    &.highlight {
      background-color: #FDEDD7;
      border: 1px solid #F17F2C;

      p.active {
        color: $orange !important;
      }
    }

    border-radius: 30px;
    padding: 2px;
    z-index: 1;
    position: relative;
    margin-bottom: 10px;

    .list-item-content {
      background-color: transparent;
      padding: 5px 10px;
      border-radius: 50px;
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      color: $orange;
      line-height: 25px;
      font-weight: 400;
      align-items: center;
      @media (min-width: 578px) {
        padding: 5px 10px;
        font-size: 1rem;
      }

      span {
        color: $black;
      }
    }
  }
}

.fade-item {
  transition: 2s all ease-in-out;
  opacity: 0;
}

.fadein {
  animation: fadeIn 0.9s 1;
  animation-fill-mode: forwards;
}

.fadeout {
  animation: fadeOut 0.9s 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.text-dark {
  color: $black;
}

.text-highlight {
  color: $primary-tone;
}

.modal-backdrop {
  z-index: 9998;

  &.in {
    opacity: .7;
  }
}

.modal.fade {
  z-index: 9999;
}

.modal-alt {
  .modal-body {
    padding: 1.5rem;
    @media (min-width: 578px) {
      padding: 5rem;
    }
    @media (max-width: 578px) {
      min-height: 460px;
    }

    h1 {
      background-color: #FDEDD7;
      border-radius: 25px;
      font-size: 2rem;
      margin: 2rem 2rem;
      padding: 1rem 1.5rem;
      @media (min-width: 578px) {
        margin: 2rem 5rem;
        font-size: 3.5rem;
        padding: 2rem;
      }
    }

    h3 {
      font-size: 1.8rem;
      @media (min-width: 578px) {
        font-size: 2.5rem;
      }
    }

    p {
      color: $grey-800;
      @media (max-width: 578px) {
        font-size: 1.4rem;
      }
    }
  }
}

.m-b-3 {
  margin-bottom: 3rem;
}

.m-t-2 {
  margin-top: 2rem;
}

.fade-list-item {
  h3 {
    font-weight: $fw-bold;
    height: 2.6em;
  }

  h2 {
    span {
      display: inline-block;

      &.d-none {
        display: none !important;
      }
    }
  }
}

.loading {
  font-size: 30px;
  width: 1.25em;
  text-align: left;
  line-height: 1em;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1em;
  }
}

.submit-btn {
  .lds-ring {
    height: 20px;
    width: 20px;
    vertical-align: sub;
  }
}

.loading-state .lds-ring {
  vertical-align: middle;
}

.add-geocode {
  @media (min-width: 578px) {
    width: 300px;
  }
}

.w-result {
  filter: blur(4px);

  &.info-carrier::before {
    content: "Praesent tempus";
  }

  &.info-country::before {
    content: "Maecenas";
  }

  &.info-city::before {
    content: "Aliquam cursus";
  }
}

.main-wrap .top-wrap {
  .phone-wrap {
    background-image: url("../../images/banner/bg-phone.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    width: 100%;
    max-width: 296px;
    min-height: 557px;
    margin: 5rem auto;
    padding: 15.5% 3.2rem;
    transform: translateY(150%);
    height: 100vh;
    max-height: 557px;
    @media (min-width: 578px) {
      padding: 5.5% 3.2rem;
    }

    h1 {
      font-size: 1.8rem;

      &:first-line {
        color: $orange;
      }

      @media (min-width: 578px) {
        font-size: 1.8rem;
      }
    }

    h3 {
      font-size: 1.6rem;
      line-height: 1.2em;
    }

    p {
      font-size: 1.2rem;
      color: $grey-800;
      font-weight: 400;
    }

    .locating-wrap, .located-wrap {
      height: 100%;

      h1 {
        margin-bottom: 4rem;
      }

      .btn-primary {
        display: block;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        font-size: 1.4rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
      }
    }

    .located-wrap {
      p {
        color: $grey-800;
        font-weight: 400;
        font-size: 1.1rem;
      }
    }
  }
}

.waves-wrap {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  .waves-animation {
    width: 180vw;
    margin: auto;
    @media (min-width: 578px) {
      width: 90vw;
      max-width: 75rem;
    }
  }
}

.form-input-group {
  position: relative;

  .input-group-icon {
    position: absolute;
    right: 1rem;
    top: 1.1rem;

    .icon {
      width: 2rem;
      cursor: pointer;
    }
  }
}

.alert-fixed {
  position: fixed;
  z-index: 4500;
  width: 90vw;
  max-width: 800px;
  top: 0;
  left: 50%;
  transform: translateX(-50%) !important;
  @at-root .navbar-side + .main-wrap & {
    @media (min-width: 578px) {
      transform: translateX(calc(-50% + 35px)) !important;
    }
  }
}

.form-wrap {
  &[busy] {
    button {
      background-color: $grey-800;
      pointer-events: none !important;
      color: $black;
    }
  }
}

.switch-toggle {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 1.5rem;
  width: 50px;
  height: 30px;
  background: #ddd;
  border-radius: 50px;
  transition: background .2s ease-in-out;
  cursor: pointer;

  &-container {
    position: relative;
    display: inline-block;
  }

  &-input {
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + .switch-toggle {
      background: #30e16b;
    }

    &:checked + .switch-toggle::after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }

    &:disabled {
      pointer-events: none;
    }

    &:disabled + .switch-toggle {
      cursor: not-allowed;
      background: #eaeaea;
    }

    &:disabled + .switch-toggle::after {
      background: #f8f8f8;
    }

    .switch-toggle-container:active &:not([disabled]) + .switch-toggle::after {
      width: 34px;
    }

    &:focus + .switch-toggle,
    .switch-toggle-container:active &:not([disabled]) + .switch-toggle {
      outline: 2px solid #5195fe;
      outline-offset: 2px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);
    transition: left .2s ease-in-out, width .2s ease-in-out, transform .2s ease-in-out;
  }
}

.w-ico {
  padding-left: 2.5rem;
  background-size: 1.7rem auto;
  background-position: left 2px;
  background-repeat: no-repeat;
  max-width: 100%;
  overflow-x: scroll;

  &.ico-calendar {
    background-image: url('../../images/ico/calendar.svg');
  }

  &.ico-clock {
    background-image: url('../../images/ico/clock.svg');
  }
}

.filter-status-wrap {
  width: calc(100% + 1.5rem);
  overflow-x: scroll;
  padding-bottom: 1rem;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.form-cb-as-btn {
  width: max-content;
  font-size: 1.4rem;

  input[type=radio] {
    display: none;

    &:checked + label {
      background-color: #E15D15;
      color: $white;
    }
  }

  label {
    display: inline-block;
    background-color: #FEF7EE;
    padding: .7rem 2rem;
    color: #E15D15;
    border-radius: 3rem;
    margin-right: .5rem;
    cursor: pointer;
    line-height: 1em;
  }
}

.pagination-wrap {
  font-size: 1.4rem;
}

.data-toggler {
  &[aria-expanded=true] {
    .toggler {
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.loader-wrap {
  padding: 3rem 0;
  text-align: center;

  .lds-ring {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.hide-scrolls {
  & > * {
    overflow: hidden;
  }
}

.v-center-align-container {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;

  .loading-wrap {
    width: 90vw;
    max-width: 420px;
  }
}

.drop-shadow-img {
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at center, rgba(244, 149, 67, 1) 0%, rgba(244, 149, 67, 0) 65%, rgba(244, 149, 67, 0) 100%);
  }

  img {
    margin: auto;
  }
}

.ico-section {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 0;
  @media (max-width: 578px) {
    &.xs-at-left {
      left: -4rem;
    }
  }
  @media (min-width: 578px) {
    &.md-at-right {
      right: -4.8rem;
    }
    &.md-at-left {
      left: -4.7rem;
    }
  }
}

.characteristics-img {
  padding: 0 3rem;
  @media (min-width: 578px) {
    padding: 5rem;
  }

  .drop-shadow-img {
    &:before {
      background: radial-gradient(ellipse at center, rgba(244, 149, 67, 1) 0%, rgba(244, 149, 67, 0) 70%, rgba(244, 149, 67, 0) 100%);
    }
  }
}

.is-mobile {
  .navbar-side {
    position: fixed;
    width: 100%;
    z-index: 8000;
  }
}

.img-wrap {
  img {
    margin: auto;
  }
}

.header-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @at-root .is-logged & {
    grid-template-rows: 1fr;
  }
  @at-root  &.showmenu {
    grid-template-rows: repeat(2, 1fr);
  }
  @media (min-width: 578px) {
    grid-template-columns: 2fr 6fr 2fr;
    grid-template-rows: 1fr !important;
  }

  .logo-container {
    grid-area: 1 / 1 / 2 / 2;
    @media (min-width: 578px) {
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  .scroller-wrap {
    font-size: 1.6rem;
    width: calc(100% + .9rem);
    overflow-x: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .header-nav {
    grid-area: 2 / 1 / 3 / 3;
    @media (min-width: 578px) {
      grid-area: 1 / 2 / 2 / 3;
    }
    font-size: 1.6rem;

    .nav-items-container {
      width: max-content;
      margin: .8rem auto 0;
      @media (min-width: 578px) {
        margin-top: .6rem;
      }
    }

    a {
      margin: 0 1.5rem;
      font-weight: 500;
      display: inline-block;

      &:hover, &.active {
        color: $primary-tone;
      }
    }
  }

  .links-container {
    justify-content: end;
    grid-area: 1 / 2 / 2 / 3;
    @media (min-width: 578px) {
      grid-area: 1 / 3 / 2 / 4;
    }

    .dropdown-toggle {
      background-color: $white;
      border-radius: 3rem;
      margin: 0 0 0 .5rem;
      color: $black;
      padding: .2rem 2.5rem .2rem 1.5rem;
      display: block;
      @media (max-width: 340px) {
        padding: .2rem 2rem .2rem 1rem;
      }

      &:after {
        content: "";
        background-image: url('../../images/ico/c-down.svg');
        background-size: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        right: .7rem;
        width: 1rem;
        height: .6rem;
        transform: translate(0, -50%);
      }

      .fi {
        width: 1.8rem;
      }
    }

    a.btn {
      margin: 0 .5rem;
      background-color: $primary-tone;
      display: block;
      text-decoration: none;
      padding: .6rem 1.5rem;
      font-size: 1.6rem;
      line-height: 1.2em;
      display: flex;
      height: fit-content;
      @media (max-width: 340px) {
        margin: 0 0 0 .5rem;
        padding: .6rem .5rem;
      }
      @media (max-width: 578px) {
        &.btn-warning {
          .ico-wrap {
            display: none;
          }
        }
      }

      .ico-wrap {
        width: 1.9rem;
        display: inline-block;
        margin-right: 1rem;

        img {
          filter: brightness(100%) saturate(100%);
        }
      }
    }
  }
}

.alert-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 1.5rem;
  top: 13%;
  @media (max-width: 578px) {
    top: 125px;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }

  &.alert-warning {
    background-color: #FEF8EA;
    border: 1px solid #F3C01C;
  }

  &.alert-fixed {
    max-width: 550px;
  }

  .ico-wrap {
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    align-self: flex-start;
    margin: 0 1.5rem 0 0;
  }

  .alert-text {
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    align-self: auto;

    h3 {
      margin-top: 0;
      font-size: 1.5rem;
      font-weight: bold;
    }

    p {
      font-size: 1.3rem;
      font-weight: 400;
      color: $grey-900;
      margin-bottom: 0;
      @media (min-width: 578px) {
        font-size: 1.5rem;
      }
    }
  }

  & > button {
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    align-self: auto;

    &.close {
      opacity: 1;
      background-color: transparent;
      padding: .5rem .7rem;
      display: block;
      border-radius: .6rem;
      font-size: 2rem;
      color: $black;

      &:hover {
        background-color: $white;
      }
    }
  }
}

.d-xs-none {
  display: none;
}

.m-xs-0 {
  margin: 0;
}

@media (min-width: 578px) {
  .d-sm-none {
    display: none;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .max-sm-h-50-vh {
    max-height: 50vh;
  }
}

.item-result-collapse {
  background-color: $white;
  margin-bottom: 1rem;
  border-radius: 1.6rem;
  padding: 1rem .5rem;

  p {
    color: $black;
  }

  p, h3 {
    font-size: 1.4rem;
    font-weight: 400;
  }

  .txt-muted {
    color: $grey-400;
  }

  .ico-wrap {
    display: inline-block;
    vertical-align: sub;

    img {
      display: block;
    }
  }
}

.location-error {
  background-color: #F8EBE3;
  border: 0.5px solid #FF4047;
  border-radius: 0.8rem;
  color: #FF4047;
  display: block;
  margin: 3% auto;
  padding: 3% 1%;
  text-align: center;
  width: 100%;
  font-weight: bold;
}

.no-results-row {
  p {
    font-size: 1.6rem;
    margin: 2.5rem auto;
    @media (min-width: 578px) {
      font-size: 2rem;
      margin: 5rem auto;
    }
    max-width: 500px;
    font-weight: 400;
  }
}

.breadcrumb-wrap {
  margin-bottom: 2.5rem;
  @media (min-width: 578px) {
    margin-bottom: 5rem;
  }

  a {
    color: $orange;

    &:hover, &:focus {
      color: $orange-600;
    }
  }
}

.scroller-wrap {
  width: calc(100% + 1.5rem);
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .scroller-content {
    width: max-content;
  }
}

.popover {
  border-radius: 1.5rem;
  max-width: calc(100vw - 20px);
  padding: 2rem 1.5rem;
  z-index: 10000;
  @media (min-width: 578px) {
    padding: 2rem 4rem;
    max-width: 525px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-weight: bold;
  }

  p {
    color: #999999;
  }

  &-close {
    width: 1.2rem;
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 1rem;
  }
}

.alt.main-wrap.home-wrap {
  .fw-bold {
    font-weight: $fw-bold;
  }

  .text-gray {
    color: $grey-400;
  }

  h1 {
    font-size: 4.8rem;
    font-weight: bold;
    color: $black;
    @media (min-width: 578px) {
      font-size: 5.8rem;
    }
  }

  h2 {
    font-size: 3.6rem;
    font-weight: 700;
  }

  h3 {
    font-size: 2.4rem;
    font-weight: 700;
  }

  h4 {
    font-size: 2rem;
    color: $black;
    font-weight: 400;
  }

  p {
    font-size: 1.8rem;
    font-weight: 400;

    small {
      font-size: 1.6rem;
    }
  }

  .btn-outline-primary {
    color: $black;
    padding: 1.2rem 1.2rem 1.2rem 2.4rem;
    border: 1px solid $primary-tone;
    background-color: $white;

    img {
      display: inline-block;
      margin-left: 1rem;
    }
  }

  .btn-primary {
    color: $white;

    img {
      display: inline-block;
      margin-left: 1rem;
    }
  }

  .banner-wrap {
    background-color: #F2F2F2;
    border-radius: 4rem;
    @media (max-width: 578px) {
      overflow: hidden;
      gap: 0;
    }
    @media (min-width: 768px) {
      margin-left: -6rem;
      margin-right: -6rem;
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }

  .step-wrap {
    background-color: #F2F2F2;
    border-radius: 2.7rem;
    text-align: center;
    padding: 3rem 4rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      color: $black;
    }
  }

  .ft-wrap {
    background-color: $white;
    border-radius: 3.2rem;
    text-align: center;
    padding: 3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .bg-gradient-wrap {
    background-image: url("../../images/landing/bg-gradient.svg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    .description-wrap {
      text-align: center;
      @media (min-width: 578px) {
        text-align: left;
      }
    }
  }

  .bg-top-banner-wrap {
    h3 {
      font-weight: 400;
    }

    background-image: url("../../images/landing/bg-banner-top-m.svg");
    @media (min-width: 578px) {
      background-image: url("../../images/landing/bg-banner-top.svg");
    }
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 25vh;

    .description-wrap {
      top: 5rem;
      z-index: 1;
      @media (max-width: 578px) {
        margin-bottom: 5rem;
      }
    }

    .result-example-wrap {
      max-width: 60%;
      position: absolute;
      right: 3%;
      top: 15%;
      @media (min-width: 578px) {
        max-width: 254px;
        right: -5%;
      }
    }

    .offset-img {
      bottom: -10rem;
      @media (min-width: 578px) {
        bottom: -15rem;
      }
    }

    .ico-dec {
      width: 4rem;
      height: 4rem;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: block;
      @media (min-width: 578px) {
        width: 6rem;
        height: 6rem;
      }

      &.ic-1 {
        background-image: url("../../images/landing/ic-1.svg");
        left: 2%;
        top: -10%;
        @media (min-width: 578px) {
          left: -5%;
          top: -15%;
        }
      }

      &.ic-2 {
        background-image: url("../../images/landing/ic-2.svg");
        left: 10%;
        top: 2%;
        @media (min-width: 578px) {
          left: 5%;
          top: 2%;
        }
      }

      &.ic-3 {
        background-image: url("../../images/landing/ic-3.svg");
        right: 2%;
        top: -50%;
        @media (min-width: 578px) {
          right: 0%;
          top: -15%;
        }
      }
    }
  }

  &.main-wrap.home-wrap {
    .questions-list {
      li {
        border: 1px solid #DDDDDE;
        border-radius: 2rem;

        &.active {
          border: 1px solid $primary-tone;
        }

        h3 {
          font-size: 1.6rem;
          font-weight: 500;
        }

        p {
          font-size: 1.4rem;
          color: $grey-400;
        }
      }
    }
  }

  .ready-banner-wrap {
    padding: 5rem 0 0;
    text-align: center;
    flex-direction: column;

    @media (min-width: 578px) {
      flex-direction: row;
      text-align: left;
    }

    p {
      color: $black;
    }
  }

  .location-banner-wrap {
    text-align: center;
    align-items: center;
    @media (max-width: 578px) {
      justify-content: center;
    }
    @media (min-width: 578px) {
      text-align: left;
      h1 {
        width: 115%;
      }
      .offset-img {
        margin-top: -5rem;
      }
    }
  }
}

.row.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  @media (max-width: 578px) {
    gap: 1.5rem;
  }

  > div {
    flex-grow: 1;
    @media (max-width: 578px) {
      flex-basis: 100%;
    }
  }
}

.alt-style {
  .logo {
    background-image: url("../../images/landing/logo-black.svg");
  }

  .language-selector {
    .dropdown-toggle {
      -webkit-box-shadow: 0 4px 14px 0 rgba(29, 29, 31, .06);
      box-shadow: 0 4px 14px 0 rgba(29, 29, 31, .06);
    }
  }

  .header-wrap {
    &.bg-dark {
      background-color: #FEF7EE;
      -webkit-box-shadow: 0 2px 12px 0 rgba(241, 127, 44, .2);
      box-shadow: 0 2px 12px 0 rgba(241, 127, 44, .2);
    }
  }

  .footer-nav-wrap {
    border-top: 1px solid #E1E4ED;
    background-color: $white;
    color: #777777;

    p, a {
      color: #777777;

      small {
        font-size: 1.5rem;
      }
    }

    .btn-primary {
      background-color: $black;
      color: $white;
    }
  }

  .footer-wrap {
    &.bg-dark {
      background-color: $white;

      * {
        color: $black;
      }

      .footer-content {
        border-top: 1px solid #E1E4ED;
        padding-top: 1rem;
      }
    }
  }
}

div.swal2-container {
  z-index: 9999;

  div.swal2-popup {
    border-radius: .8rem;
    -webkit-box-shadow: 0 6px 15px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, .1);
    width: 95vw !important;
    max-width: 650px !important;
  }

  &.swal2-top > .swal2-popup {
    top: 10rem;
  }

  .swal2-html-container {
    margin: 2rem 2rem 1rem 2rem;
    @media (min-width: 578px) {
      margin: 4rem 7rem 1rem 7rem;
    }

    h1 {
      font-size: 3.2rem;
      line-height: 1em;
    }

    p {
      font-size: 1.6rem;
    }

    h3 {
      color: #999999;
      font-size: 1.6rem;
      line-height: 1.2em;
    }
  }

  button.swal2-close {
    color: #272727;
    right: .5rem;
    top: .5rem;

    &:hover {
      background-color: #EBEBEB;
      color: #272727;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .swal2-actions {
    margin: 1rem 3rem 3rem 3rem;
    width: calc(100% - 6rem);
    column-gap: 2rem;
    gap: 1rem;

    button {
      flex: 1 1 0px;
      border-radius: 3rem;
      box-shadow: 0px 2px 6px 0px rgba(29, 29, 31, 0.14);
      font-size: 1.8rem;
      font-weight: 700;
      line-height: .77em;
      padding: 1.6rem 3rem;
      min-width: 25rem;
    }
  }
}

.light-card {
  background-color: $white;
  padding: 1.5rem;
  border-radius: 1.6rem;
  @media (min-width: 578px) {
    padding: 4.5rem 9rem;
  }
}

.alert-card {
  border: 1px solid #DDDDDE;
  -webkit-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, .05);
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, .05);
  padding: 1.5rem;
}

.hidden-content-d-flex {
  display: flex;
  flex-direction: column;

  .main-wrap {
    flex: auto;
  }
}

.blog-wrap {
  h1 {
    color: $grey-900;
    font-weight: bold;
  }

  .breadcrumb-wrap {
    margin-bottom: 0rem;
    @media (max-width: 578px) {
      margin-top: 2rem;
    }
  }

  @at-root .main-wrap.home-wrap & {
    h2 {
      color: $grey-900;
    }
  }

  .recents-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 578px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .banner-wrap {
    background-color: #F2F2F2;
    border-radius: 4rem;
    @media (max-width: 578px) {
      overflow: hidden;
      gap: 0;
    }
    @media (min-width: 768px) {
      margin-left: -6rem;
      margin-right: -6rem;
      padding-left: 6rem;
      padding-right: 6rem;
    }

    a {
      img {
        display: inline-block;
      }
    }
  }

  .ready-banner-wrap {
    flex-direction: column;
    @media (max-width: 578px) {
      text-align: center;
    }
    @media (min-width: 578px) {
      flex-direction: row;
    }

    h1 {
      color: $grey-900;
    }

    p {
      color: $grey-900;
    }
  }

  .info-wrap {
    &.over {
      position: absolute;
      bottom: 0;

      padding: 2.5rem 2.5rem 1.5rem;
      z-index: 1;
      max-width: 100%;
      text-align: left;
      @media (min-width: 578px) {
        max-width: 60%;
        left: 3.5rem;
        padding: 2.5rem;
      }

      h1 {
        color: $white;
        font-size: 2.4rem;
        text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
        margin: 0 auto 1rem;
        font-weight: 900;
        @media (min-width: 578px) {
          font-size: 4.8rem;
        }
      }

      p {
        font-size: 1.5rem;
        color: $white;
        text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
      }
    }
  }

  img {
    border-radius: 2.4rem;
  }

  h3 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    font-weight: 900;
    @media (min-width: 578px) {
      font-size: 3.2rem;
    }
  }

  h4 {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .post-wrap {
    border-radius: 2.4rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .05);
    padding: 1.5rem;
    border: 1px solid #DDDDDE;
    display: block;

    &:hover {
      border: 1px solid #F17F2C;
      box-shadow: 0 4px 12px rgba(241, 127, 44, .15);
    }

    p {
      text-align: right;
    }
  }

  .post-content-wrap {
    font-size: 1rem;

    img {
      margin-top: 2rem;
      margin-bottom: 2rem;
      max-width: 100%;
    }

    p, ul, ol, li {
      font-size: 1.6rem;
      color: #515151;
    }

    ul {
      padding-left: 4rem;
    }

    h1, h2, h3, h4, strong {
      color: #1D1D1F !important;
    }

    h3 {
      font-size: 2.4rem;
    }

    & > p, & > h1, & > h2, & > h3, & > h4, & > h5, & > h6, & > strong, & > em, & > abbr, & > acronym, & > address, & > bdo, & > blockquote,
    & > cite, & > q, & > code, & > ins, & > del, & > dfn, & > kbd, & > pre, & > samp, & > var, & > a, & > base, & > ul, & > ol, & > dl, & > table,
    & > form, & > input, & > textarea, & > select, & > option, & > optgroup, & > button, & > label, & > fieldset, & > legend {
      color: #515151;
    }
  }

  .bg-gradient {
    @media (max-width: 578px) {
      height: 50vh;
      img {
        object-fit: cover;
        object-position: center;
        height: 100%;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 104%;
      height: 100%;
      left: -2%;
      background: #000;
      transition: all .3s;
      background: linear-gradient(0deg, rgba(0, 0, 0, .7) 0%, transparent 98%);
      z-index: 0;
    }

    overflow: hidden;
    border-radius: 2.4rem;
    display: block;
  }
}

.ck_homepage .blog-wrap {
  .container > h3 {
    display: none;
  }
}

.btn-wrap {
  a.btn {
    img {
      display: inline-block;
    }
  }
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}
