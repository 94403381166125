/*iPad in portrait & landscape*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  { /* STYLES GO HERE */}

/*iPad in landscape*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) { /* STYLES GO HERE */}

/*iPad in portrait*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) { /* STYLES GO HERE */ }

/*iPad 3 & 4 Media Queries*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 2) { /* STYLES GO HERE */}

/*Retina iPad in landscape*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2) { /* STYLES GO HERE */}

/*Retina iPad in portrait*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 2) { /* STYLES GO HERE */ }

/*iPad 1 & 2 Media Queries*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 1){ /* STYLES GO HERE */}

/*iPad 1 & 2 in landscape*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1)  { /* STYLES GO HERE */}

/*iPad 1 & 2 in portrait*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 1) { /* STYLES GO HERE */ }

/*iPhone 6 in portrait & landscape*/
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) { /* STYLES GO HERE */}

/*iPhone 6 in landscape*/
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) { /* STYLES GO HERE */}

/*iPhone 6 in portrait*/
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) { /* STYLES GO HERE */ }

/*iPhone 6 Plus in portrait & landscape*/
@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px) { /* STYLES GO HERE */}

/*iPhone 6 Plus in landscape*/
@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : landscape) { /* STYLES GO HERE */}

/*iPhone 6 Plus in portrait*/
@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : portrait) { /* STYLES GO HERE */ }

/*iPhone 5 & 5S in portrait & landscape*/
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px) { /* STYLES GO HERE */}

/*iPhone 5 & 5S in landscape*/
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) { /* STYLES GO HERE */}

/*iPhone 5 & 5S in portrait*/
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) { /* STYLES GO HERE */ }

/*iPhone 2G-4S in portrait & landscape*/
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) { /* STYLES GO HERE */}

/*iPhone 2G-4S in landscape*/
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px)
and (orientation : landscape) { /* STYLES GO HERE */}

/*iPhone 2G-4S in portrait*/
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px)
and (orientation : portrait) { /* STYLES GO HERE */ }
@media only screen and (max-device-width: 320px){
    .steps.simple-list{
        min-height: 80px;
    }
}
@media only screen and (min-device-width: 320px){
    .steps.simple-list{
        min-height: 60px;
    }
}
/*Phones*/
@media only screen and (max-device-width : 736px){
    .logo{
        margin-right: auto;
    }
    .paypal-wrap{
        width: 90%;
        max-width: 570px;
    }
    .fancy-container.minified .fancybox-inner{
        height: 35px !important;
    }
    .fancy-container.minified{
        top: 15% !important;
    }

    .steps li:before{
        height: 100px;
        margin: 2.5rem auto;
    }
    .payment-content-wrap{
        padding-top: 2rem;
        padding-bottom: 3rem;
    }
    .ico-help{
        right: 5%;
    }
}
/*Phones*/

@media only screen and (min-device-width : 736px){

    .fancy-container.minified{
        top: 150px !important;
    }
    .steps li:before{
        height: 122px;
        margin: 3.5rem auto;
    }
}
/*Desktop*/
@media only screen and (min-device-width : 1024px){
    h2{
        font-size: 4.4rem;
        margin-bottom: 2rem;
        color: #ea6c27;
    }
}
/*Desktop*/

/*Laptop*/
@media only screen and (min-device-width : 1100px) and (max-device-width : 1280px){ /* STYLES GO HERE */ }
/*Laptop*/
