:root {
  --form-control-color: $orange;
  --form-background: $orange;
}

.custom-radio-input {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  place-content: center;
  margin: 1rem 0 0;
}

.custom-radio-input + .custom-radio-input {
  margin-top: 1em;
}

.custom-radio-input:focus-within {
  color: var(--form-control-color);
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid $grey;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: $orange;
}
input[type="radio"]:checked{
  border-color: $orange;
}
input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: none;
}
