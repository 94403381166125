/*GENERAL*/
body {
    font-family: inherit;
}

a {
    color: inherit;
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}

a:focus {
    outline: none;
    outline-offset: initial;
}

/*GENERAL*/
/*FORMS*/
.form-control::-moz-placeholder {
    color: #666666;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #666666;
}

.form-control::-webkit-input-placeholder {
    color: #666666;
}

textarea {
    resize: none;
}

/*FORMS*/
/*CONTAINERS*/
.site-min-width {
    min-width: 1170px;
}

.fixed-container {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.fixed-container:before,
.fixed-container:after {
    content: " ";
    display: table;
}

.fixed-container:after {
    clear: both;
}

/*CONTAINERS*/
/*MEDIA*/
.responsive-image {
    max-width: 100%;
}

/*MEDIA*/
/*LISTS*/
.list-unstyled {
    margin-bottom: 0;
}

/*LISTS*/
/*MARGINS*/
.row-margin {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.m-xs-t-0 {
    margin-top: 0;
}

.m-xs-t-1 {
    margin-top: 1rem;
}

.m-xs-t-2 {
    margin-top: 2rem;
}

.m-xs-t-3 {
    margin-top: 3rem;
}

.m-xs-t-4 {
    margin-top: 4rem;
}

.m-xs-t-5 {
    margin-top: 5rem;
}

.m-xs-r-0 {
    margin-right: 0;
}

.m-xs-r-1 {
    margin-right: 1rem;
}

.m-xs-r-2 {
    margin-right: 2rem;
}

.m-xs-r-3 {
    margin-right: 3rem;
}

.m-xs-r-4 {
    margin-right: 4rem;
}

.m-xs-r-5 {
    margin-right: 5rem;
}

.m-xs-b-0 {
    margin-bottom: 0;
}

.m-xs-b-1 {
    margin-bottom: 1rem;
}

.m-xs-b-2 {
    margin-bottom: 2rem;
}

.m-xs-b-3 {
    margin-bottom: 3rem;
}

.m-xs-b-4 {
    margin-bottom: 4rem;
}

.m-xs-b-5 {
    margin-bottom: 5rem;
}

.m-xs-l-0 {
    margin-left: 0;
}

.m-xs-l-1 {
    margin-left: 1rem;
}

.m-xs-l-2 {
    margin-left: 2rem;
}

.m-xs-l-3 {
    margin-left: 3rem;
}

.m-xs-l-4 {
    margin-left: 4rem;
}

.m-xs-l-5 {
    margin-left: 5rem;
}

.m-xs-tb-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.m-xs-tb-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.m-xs-tb-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.m-xs-tb-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.m-xs-tb-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.m-xs-tb-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.align-center {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.d-flex {
    display: flex;
}

.d-none {
    display: none;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}


@media (min-width: 768px) {
    .d-sm-flex {
        display: flex;
    }

    .d-sm-block {
        display: block;
    }

    .d-sm-inline-block {
        display: inline-block;
    }
}

@media (min-width: 992px) {
    .d-md-flex {
        display: flex;
    }

    .d-md-block {
        display: block;
    }

    .d-md-inline-block {
        display: inline-block;
    }
}

@media (min-width: 1200px) {
    .d-lg-flex {
        display: flex;
    }

    .d-lg-block {
        display: block;
    }

    .d-lg-inline-block {
        display: inline-block;
    }
}

@media (min-width: 768px) {
    .m-sm-t-0{
        margin-top: 0;
    }
    .m-sm-t-1 {
        margin-top: 1rem;
    }

    .m-sm-t-2 {
        margin-top: 2rem;
    }

    .m-sm-t-3 {
        margin-top: 3rem;
    }

    .m-sm-t-4 {
        margin-top: 4rem;
    }

    .m-sm-t-5 {
        margin-top: 5rem;
    }

    .m-sm-r-1 {
        margin-right: 1rem;
    }

    .m-sm-r-2 {
        margin-right: 2rem;
    }

    .m-sm-r-3 {
        margin-right: 3rem;
    }

    .m-sm-r-4 {
        margin-right: 4rem;
    }

    .m-sm-r-5 {
        margin-right: 5rem;
    }

    .m-sm-b-0 {
        margin-bottom: 0;
    }

    .m-sm-b-1 {
        margin-bottom: 1rem;
    }

    .m-sm-b-2 {
        margin-bottom: 2rem;
    }

    .m-sm-b-3 {
        margin-bottom: 3rem;
    }

    .m-sm-b-4 {
        margin-bottom: 4rem;
    }

    .m-sm-b-5 {
        margin-bottom: 5rem;
    }

    .m-sm-l-1 {
        margin-left: 1rem;
    }

    .m-sm-l-2 {
        margin-left: 2rem;
    }

    .m-sm-l-3 {
        margin-left: 3rem;
    }

    .m-sm-l-4 {
        margin-left: 4rem;
    }

    .m-sm-l-5 {
        margin-left: 5rem;
    }

    .m-sm-tb-1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .m-sm-tb-2 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .m-sm-tb-3 {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .m-sm-tb-4 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .m-sm-tb-5 {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
}

@media (min-width: 992px) {
    .text-md-left {
        text-align: left;
    }

    .nav-md-stacked > li {
        float: none;
    }

    .m-md-t-0 {
        margin-top: 0;
    }

    .m-md-t-1 {
        margin-top: 1rem;
    }

    .m-md-t-2 {
        margin-top: 2rem;
    }

    .m-md-t-3 {
        margin-top: 3rem;
    }

    .m-md-t-4 {
        margin-top: 4rem;
    }

    .m-md-t-5 {
        margin-top: 5rem;
    }

    .m-md-r-1 {
        margin-right: 1rem;
    }

    .m-md-r-2 {
        margin-right: 2rem;
    }

    .m-md-r-3 {
        margin-right: 3rem;
    }

    .m-md-r-4 {
        margin-right: 4rem;
    }

    .m-md-r-5 {
        margin-right: 5rem;
    }

    .m-md-b-0 {
        margin-bottom: 0;
    }

    .m-md-b-1 {
        margin-bottom: 1rem;
    }

    .m-md-b-2 {
        margin-bottom: 2rem;
    }

    .m-md-b-3 {
        margin-bottom: 3rem;
    }

    .m-md-b-4 {
        margin-bottom: 4rem;
    }

    .m-md-b-5 {
        margin-bottom: 5rem;
    }

    .m-md-l-1 {
        margin-left: 1rem;
    }

    .m-md-l-2 {
        margin-left: 2rem;
    }

    .m-md-l-3 {
        margin-left: 3rem;
    }

    .m-md-l-4 {
        margin-left: 4rem;
    }

    .m-md-l-5 {
        margin-left: 5rem;
    }

    .m-md-tb-1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .m-md-tb-2 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .m-md-tb-3 {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .m-md-tb-4 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .m-md-tb-5 {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
}

@media (min-width: 1200px) {
    .m-lg-t-1 {
        margin-top: 1rem;
    }

    .m-lg-t-2 {
        margin-top: 2rem;
    }

    .m-lg-t-3 {
        margin-top: 3rem;
    }

    .m-lg-t-4 {
        margin-top: 4rem;
    }

    .m-lg-t-5 {
        margin-top: 5rem;
    }

    .m-lg-r-1 {
        margin-right: 1rem;
    }

    .m-lg-r-2 {
        margin-right: 2rem;
    }

    .m-lg-r-3 {
        margin-right: 3rem;
    }

    .m-lg-r-4 {
        margin-right: 4rem;
    }

    .m-lg-r-5 {
        margin-right: 5rem;
    }

    .m-lg-b-1 {
        margin-bottom: 1rem;
    }

    .m-lg-b-2 {
        margin-bottom: 2rem;
    }

    .m-lg-b-3 {
        margin-bottom: 3rem;
    }

    .m-lg-b-4 {
        margin-bottom: 4rem;
    }

    .m-lg-b-5 {
        margin-bottom: 5rem;
    }

    .m-lg-l-1 {
        margin-left: 1rem;
    }

    .m-lg-l-2 {
        margin-left: 2rem;
    }

    .m-lg-l-3 {
        margin-left: 3rem;
    }

    .m-lg-l-4 {
        margin-left: 4rem;
    }

    .m-lg-l-5 {
        margin-left: 5rem;
    }

    .m-lg-tb-1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .m-lg-tb-2 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .m-lg-tb-3 {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .m-lg-tb-4 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .m-lg-tb-5 {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
}

/*MARGINS*/

.p-0 {
    padding: 0;
}

.p-l-0 {
    padding-left: 0;
}

.p-r-0 {
    padding-right: 0;
}

.ml-auto {
    margin-left: auto;
}

.justify-content-between {
    justify-content: space-between;
}

.table-responsive {
    border: none;
    margin: 0;
}
