$black: #000000;
$white: #ffffff;
$orange: #F17F2C;
$grey: #858585;
$grey-400: #999999;
$grey-800: #797979;
$grey-900: #1D1D1F;

$primary-tone: $orange;
$muted: $grey;
$primary-tone-vivid: $orange;
$primary-tone-muted: #ED6400;

$fw-bold: 700;

$blue: #2E8AF5;
$green: #22B867;
$orange-600: #E15D15;
